import * as React from 'react';
import {graphql, usePreloadedQuery} from 'react-relay';
import {RelayProps} from 'relay-nextjs';
import {AppLayout} from '../../components/layouts/AppLayout';
import {LoggedInHomePageQuery as LoggedInHomePageQueryType} from '../../queries/__generated__/LoggedInHomePageQuery.graphql';
import {EntryFeed} from '../entries/EntryFeed';

export const LoggedInHomePageQuery = graphql`
  query LoggedInHomePageQuery {
    ...AppLayout_query
    ...EntryFeed_query
  }
`;

export const LoggedInHomePage: React.FC<
  RelayProps<{}, LoggedInHomePageQueryType>
> = ({preloadedQuery}) => {
  const query = usePreloadedQuery<LoggedInHomePageQueryType>(
    LoggedInHomePageQuery,
    preloadedQuery,
  );

  return (
    <AppLayout query$key={query}>
      <EntryFeed query$key={query} />
    </AppLayout>
  );
};
