/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LoggedInHomePageQueryVariables = {};
export type LoggedInHomePageQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"AppLayout_query" | "EntryFeed_query">;
};
export type LoggedInHomePageQuery = {
    readonly response: LoggedInHomePageQueryResponse;
    readonly variables: LoggedInHomePageQueryVariables;
};



/*
query LoggedInHomePageQuery {
  ...AppLayout_query
  ...EntryFeed_query
}

fragment AppLayout_query on Query {
  me {
    user {
      ...UserAvatar_user
      id
    }
  }
}

fragment EntryFeed_query on Query {
  me {
    user {
      ...EntryFeed_user
      id
    }
  }
}

fragment EntryFeed_user on User {
  entryConnection(first: 10) {
    edges {
      node {
        id
        type
        ...EntryPreview_entry
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  id
}

fragment EntryPreview_entry on Entry {
  id
  type
  createdAt
  ...useDecryptedEntry_entry
}

fragment UserAvatar_user on User {
  name
}

fragment useDecryptedEntry_entry on Entry {
  id
  type
  version
  verification
  data
  embeddings
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoggedInHomePageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AppLayout_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "EntryFeed_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LoggedInHomePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerContext",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              (v0/*: any*/),
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EntUserToEntryConnection",
                "kind": "LinkedField",
                "name": "entryConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EntUserToEntryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Entry",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "version",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "verification",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "data",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "embeddings",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "entryConnection(first:10)"
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "filter"
                ],
                "handle": "connection",
                "key": "EntryFeed_user_entryConnection",
                "kind": "LinkedHandle",
                "name": "entryConnection"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d6a7318b225542354ef3d938d655fe08",
    "id": null,
    "metadata": {},
    "name": "LoggedInHomePageQuery",
    "operationKind": "query",
    "text": "query LoggedInHomePageQuery {\n  ...AppLayout_query\n  ...EntryFeed_query\n}\n\nfragment AppLayout_query on Query {\n  me {\n    user {\n      ...UserAvatar_user\n      id\n    }\n  }\n}\n\nfragment EntryFeed_query on Query {\n  me {\n    user {\n      ...EntryFeed_user\n      id\n    }\n  }\n}\n\nfragment EntryFeed_user on User {\n  entryConnection(first: 10) {\n    edges {\n      node {\n        id\n        type\n        ...EntryPreview_entry\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment EntryPreview_entry on Entry {\n  id\n  type\n  createdAt\n  ...useDecryptedEntry_entry\n}\n\nfragment UserAvatar_user on User {\n  name\n}\n\nfragment useDecryptedEntry_entry on Entry {\n  id\n  type\n  version\n  verification\n  data\n  embeddings\n}\n"
  }
};
})();
(node as any).hash = 'f1130960b7a44aae6178b8f4409150fd';
export default node;
