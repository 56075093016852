import * as React from 'react';
import {LoggedOutHomePage} from './LoggedOutHomePage';
import {LoggedInHomePage, LoggedInHomePageQuery} from './LoggedInHomePage';
import {NextPage} from 'next';
import {useIsAuthenticated} from '../../lib/useIsAuthenticated';
import createPreloadedPage from '../../lib/preloadedPage';

const HomePageImpl: NextPage<
  React.ComponentProps<typeof LoggedInHomePage>
> = props => {
  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated ? (
    <LoggedInHomePage {...props} />
  ) : (
    <LoggedOutHomePage />
  );
};

export const HomePage = createPreloadedPage(
  HomePageImpl,
  LoggedInHomePageQuery,
  {},
  false,
);
