import {
  Box,
  Button,
  Center,
  Flex,
  Group,
  Space,
  Text,
  Title,
} from '@mantine/core';
import * as React from 'react';

export const LoggedOutHomePage: React.FC = () => {
  return (
    <Center
      sx={{
        backgroundColor: '#f9e67e',
        backgroundImage: 'linear-gradient(45deg, #f9e67e 0%, #fbaec5 100%)',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Flex direction="column" justify="space-around" align="center">
        <Text sx={{fontSize: 72, fontWeight: 'bold', color: '#fff'}}>
          Happily
        </Text>
        <Space h="xl"></Space>
        <Flex
          direction="row"
          justify="space-around"
          align="center"
          sx={{width: '100%'}}
        >
          <Box sx={{flex: 1, marginRight: 12}}>
            <Button
              variant="outline"
              fullWidth
              component="a"
              href="/auth/signup"
            >
              Sign Up
            </Button>
          </Box>
          <Box sx={{flex: 1}}>
            <Button
              variant="outline"
              fullWidth
              component="a"
              href="/auth/login"
            >
              Login
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Center>
  );
};
